



const Jotform = () => {

  window.location.href = "https://form.jotform.com/213442486784060";
        




};
export default Jotform;
